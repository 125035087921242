<script setup>
const props = defineProps({
    products: Array,
    fallbackProducts: {
        type: Array,
        default: [],
    },
    layout: {
        type: String,
        default: 'default',
    },
})

import { GlobeEuropeAfricaIcon } from '@heroicons/vue/24/outline'
import ProductCard from './ProductCard.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import Section from '../Section.vue';

const layouts = {
    catalog: 'grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-4',
    default: 'grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4',
}
</script>

<template>
    <template v-if="products?.length">
        <div class="grid auto-rows-auto" :class="layouts[layout]">
            <ProductCard 
                v-for="product in products"
                :key="product.id"
                :product="product"
            />
        </div>
    </template>
    <template v-else>
        <div class="text-center mb-10">
            <GlobeEuropeAfricaIcon class="w-10 h-10 text-sky-600 inline-block mb-4"></GlobeEuropeAfricaIcon>
            <div class="font-bold">Нет подходящих товаров</div>
            <p class="text-gray-500">попробуйте смягчить условия поиска или поискать в другой категории</p>
            <PrimaryButton class="mt-4" :href="route('market.catalog')" >Перейти в каталог</PrimaryButton>
        </div>
        <div class="-mx-6" v-if="fallbackProducts.length > 0">
            <Section title="Рекомендуем посмотреть">
                <ProductGrid :layout="layout" :products="fallbackProducts" />
            </Section>
        </div>
    </template>
</template>